<template>
  <en-drawer :model-value="modelValue" :title="`${form.data.id ? '编辑' : '新建'}预约单`" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" :loading="form.loading">
      <en-form-item label="领料人" prop="pickedBy">
        <select-maintain
          v-model="form.data.pickedBy"
          :ajax="{
            action: 'GET /enocloud/common/user',
            params: (params, name) => (params.payload = { name })
          }"
          :props="{ label: 'name', value: 'name' }"
          remote
          allow-create
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item v-if="store.user.tenant?.id === 'ENOCH'" label="网点" prop="tenant">
        <select-maintain
          :ajax="{ action: 'GET /enospray/common/tenant', params: (params, shortName) => (params.payload = { shortName }) }"
          :disabled="Boolean(form.disabled || form.data.id)"
          :props="{ label: 'shortName', value: '' }"
          v-model="form.data.tenant"
          value-key="id"
          remote
        ></select-maintain>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment" :disabled="form.disabled"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :disabled="form.disabled" :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnosprayInventoryDefinitions['MaterialRequisitionDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (id: number | undefined) => ['number', 'undefinded'].includes(typeof id)
  },

  watch: {
    modelValue: {
      async handler() {
        this.form.init()
        if (this.modelValue) {
          this.form.data.id = this.data?.id
          if (this.form.data.id) this.form.get()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        rules: {
          pickedBy: [{ required: true, message: '请填写领料人' }]
        },
        ajax: {
          get: {
            action: 'GET /enospray/materialrequisition/:requisitionId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enospray/materialrequisition',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enospray/materialrequisition',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'A'
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const res = await this.form[this.form.data.id ? 'update' : 'submit']()
            this.emit('update:model-value', false)
            this.emit('confirm', (res.data[0] as number | undefined) || this.form.data.id)
          }
        }
      }
    }
  }
})
</script>
